body {
    background-color: black;
}

.term p {
    white-space: pre-wrap;
}

.term a {
    color: lightblue;
}

.term #termName {
    color: #02ac15;
}

.term #termPath {
    color: #067EBA;
}