html, body {
    margin: 0;
    font-family: sans-serif;
}

.stylistic {
    font-size: 2.5vh;
}

.stylistic h1 {
    font-size: 10vw;
    margin: 0;
}

.stylistic h2 {
    font-size: 3vh;
}

.stylistic h3 {
    font-size: 3vw;
}

.stylistic p {
    white-space: pre-wrap;
}

.stylistic table {
    text-align: left;
    white-space: pre-wrap;
    border: 5px solid;
    border-collapse: collapse;
}

.stylistic th {
    border: 3px solid;
    border-collapse: collapse;
}

.stylistic td {
    padding-right: 1vw;
}